import './bootstrap';
import '../scss/app.scss';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

// 載入資源
const loadResources = () => {
    // 載入金萱那提字體樣式
    const loadFonts = () => {
        const style = document.createElement('style');
        style.textContent = `
            @font-face {
                font-family: '金萱那提二分糖';
                src: url('/fonts/jinxuan-tea-2-sugar.woff2') format('woff2');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }
            @font-face {
                font-family: '金萱那提半糖';
                src: url('/fonts/jinxuan-tea-half-sugar.woff2') format('woff2');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }
            @font-face {
                font-family: '金萱那提八分糖';
                src: url('/fonts/jinxuan-tea-8-sugar.woff2') format('woff2');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }
        `;
        document.head.appendChild(style);
    };
    
    // 載入 Font Awesome 最新版
    const loadFontAwesome = () => {
        const faLink = document.createElement('link');
        faLink.rel = 'stylesheet';
        faLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
        faLink.integrity = 'sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA==';
        faLink.crossOrigin = 'anonymous';
        document.head.appendChild(faLink);
    };
    
    // 載入 CSS 檔案輔助函數
    const loadCSS = (href: string) => {
        return new Promise<void>((resolve, reject) => {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = href;
            link.onload = () => resolve();
            link.onerror = () => reject(new Error(`Failed to load CSS: ${href}`));
            document.head.appendChild(link);
        });
    };
    
    // 依序載入所有資源
    loadFonts();
    loadFontAwesome();
};

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);
        root.render(<App {...props} />);
        
        // 載入所有資源
        loadResources();
    },
    progress: {
        color: '#4f46e5',
    },
});
